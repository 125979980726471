
import { Config } from 'tradingmate-modules/src/environment'
import { Vue, Component } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'

@Component({
  components: {
  }
})

export default class MyWebsitePreview extends Vue {
  private branchId = this.$route.params.id

  private windowWidth = 0;

  get mobileDisplay (): boolean {
    return this.windowWidth < 900
  }

  get link (): string {
    // const branchId = Config.API.Base // Why was this here?
    return `${Config.API.Base}/my-websites/preview/${this.branchId}`
  }

  mounted (): void {
    this.UpdateIFrameSource()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  created (): void {
    //
  }

  destroyed (): void {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize (): void {
    console.log('handleResize')
    this.windowWidth = window.innerWidth
  }

  UpdateIFrameSource (): void {
    const preview = this.$refs.preview as HTMLIFrameElement
    // if (preview.contentWindow)
    // {
    //   preview.src = this.link
    // }
    Services.API.MyWebsites.GetWebsitePreview(this.branchId)
      .then((response) => {
        if (!preview.contentWindow) return
        console.log(response)
        preview.contentWindow.document.open()
        preview.contentWindow.document.write(response)
        preview.contentWindow.document.close()
      })
  }

  handleBuyNow (): void {
    this.$router.push({ name: 'Membership' })
  }}

